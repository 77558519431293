* {
  border: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body, html, #root {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  color:#444444;
  background: transparent !important;
  font-family: 'Roboto', sans-serif;
  font-size:15px!important;
  line-height: 1.4em;
}

#root{
  transform: translateZ(0);
  z-index: 0;
}

.cHeight{
  display: block;
  line-height: 1.4em;
}

.ro {
  border: 1px solid red;
}
.bgr{background: red;}

.wc {
  width: 100% !important;
}

.imgr {
  width: 100%;
  height: auto;
  display: block;
}

.lockProcesing {
  transition: all .5s ease-in-out;


  .circle {
    margin-right: 0;
    transition: all .5s ease-in-out;

    img {
      transform: scale(1);
      transition: all .5s ease-in-out;

    }
  }
  .circle {
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      z-index: 0;
      transition: all .5s ease-in-out;
      border: 2px dashed transparent;
      animation: girar 2s linear infinite;
    }
  }

}
.MainButton{
  .points{
    animation: points .5s linear infinite;
  }
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;/*your box-shadow*/
  -webkit-text-fill-color: #333;
}


select,input,button,textarea{

  cursor:pointer;

  &:focus{
    outline:none;
  }
}

.lockProcesing.loading {

  img{
    transform: scale(0.8);
  }

  .circle {
  margin-right: 8px;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      z-index: 0;
      border: 2px dashed white;
      animation: girar 2s linear infinite;
    }
  }
}

.thumb-horizontal{
  display: none;
}


.ReactCollapse--collapse {
  transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}



@keyframes girar {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.saving span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.saving span:nth-child(2) {
  animation-delay: .2s;
}

.saving span:nth-child(3) {
  animation-delay: .4s;
}

@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}


.patron{
    height: 100vh;
    background-size:  cover;
}


@keyframes fondoA {
  from{
    background-position: 0 0;
  }
  to{
    background-position: 0 -150px;
  }
}

@keyframes girarMancha {
  0%{
    transform: rotate(0deg);
    background-color: rgba(255,255,255,.1);
  }
  50%{
    background-color: rgba(255,255,255,.4);
  }
  100%{
    transform: rotate(360deg);
    background-color: rgba(255,255,255,.1);
  }
}

@keyframes crece {
  0%{
    opacity: 0;
    transform: scale(0.4);
  }
  75%{
    opacity: 1;
    transform: scale(1);
  }
  100%{
    opacity: 0;
    transform: scale(1.3);
  }
}
